import React, { useState, useEffect } from 'react';
import { addAndRemoveDisabledCls, isValidEmail } from '../utils/utils';
import Layout from '../components/layout';
import SEO from "../components/seo";

const ThankYou = () => {
    const seo = {
        metaDesc: 'Thank you - Inventiv.org'
    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    //
    //
    const submit = async (e) => {
        e.preventDefault();

        if (name && subject && message) {
            if ((email && isValidEmail(email))) {


                // call api
                const data = {
                    email: email,
                    name: name,
                    phone: phone,
                    subject: `[Inventiv] ${subject}`,
                    message: message,
                    site: 'inventiv.org'
                }
                fetch("https://api.trustyip.com/subcribers/add", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json"
                    },
                    "body": JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(response => {
                        alert('Thank you for contacting us. We will follow up with you soon');
                        // reset
                        setEmail('');
                        setName('');
                        setPhone('');
                        setMessage('');
                        setSubject('');
                        setEmailSent(true);
                    })
                    .catch(err => {
                        alert('There is something wrong. Please try again later!');
                        console.log(err);
                    });
                //
                addAndRemoveDisabledCls('contactBtn');
            } else {
                alert('Please enter your valid email.');
            }
        } else {
            alert('Please fill your information');
        }

    }
    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Thank you - Inventiv.org" canonical='/thank-you' seo={seo} />

            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Thank You</h1>
                    </div>
                </div>
            </div>

            {/* <!-- Rsvp start --> */}
            <div class="rsvp content-area-2" id="rsvp">
                <div class="container">

                    <div class="row">
                        <div class="col-12 col-md-12 wow fadeInUp delay-04s">    
                            <h5 className='text-center'>
                                Thank you for submitting your contact information. We will get back to you soon.
                            </h5>
                            <p>&nbsp;</p>
                            <h5 className='text-center'>
                                Inventiv.org <br/>
                                A non profit organization supporting investors and entrepreneurs worldwide
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default ThankYou;